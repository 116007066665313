import './App.css';
import tw from "./images/tw.png"
import tg from "./images/tg.png"
import dext from "./images/dext.png"
import dex from "./images/dex.png"
import addyLeft from "./images/addyNormal.png"
import about1 from "./images/about1.png"
import about2 from "./images/about2.png"
import memeBase from "./images/memeBase.png"
import { useEffect, useRef, useState } from 'react';
function App() {
  return (
    <div className="App">
      <Hero></Hero>
      <Addy></Addy>
      <Buy></Buy>
      <Generator></Generator>
      <About></About>
      <Footer></Footer>
    </div>
  );
}

function Hero(){
  return(
    <div id='Hero'>
      <div id='Menu'>
        <div id='MenuLeft'>
          <a href="#">Home</a>
          <a href="#About">About</a>
          <a href="#Buy">How to Buy</a>
          <a href="#Generator">Generator</a>
        </div>
        <div id='MenuRight'>
          <a href="https://x.com/NormalPeopleSOL">
          <img src={tw} alt="" />
          </a>
          <a href="https://t.me/NormalPeopleSOL">
          <img src={tg} alt="" />
          </a>
          <a href="#">
          <img src={dext} alt="" />
          </a>
          <a href="https://dexscreener.com/solana/CyhnY44hgDSycXmpRuhMv9Ctqyo9VFFMybhDaCsgpump"><img src={dex} alt="" /></a>
          <button onClick={e=> window.open("https://jup.ag/swap/SOL-CyhnY44hgDSycXmpRuhMv9Ctqyo9VFFMybhDaCsgpump")}>Buy $NORMAL</button>
        </div>
      </div>
    </div>
  )
}

function Addy(){
  return(
    <div id='Addy'>
      <div id='AddyTop'>
        <h2>Contract Address</h2>
        <div id='AddyTopBottom'>
          <span style={{width:"fit-content",background: "#5a002c",padding:"10px", borderRadius:"10px", color:"white", fontWeight:700}}>CA:</span>
          <span>CyhnY44hgDSycXmpRuhMv9Ctqyo9VFFMybhDaCsgpump</span>
          <button style={{width:"fit-content", padding:"15px", borderRadius: "10px"}} onClick={async () => {
            if("clipboard" in navigator) await navigator.clipboard.writeText("CyhnY44hgDSycXmpRuhMv9Ctqyo9VFFMybhDaCsgpump").then(res => console.log("Copied.")).catch(ex => console.log("Couldn't copy."))
              else document.execCommand("copy", true, "CyhnY44hgDSycXmpRuhMv9Ctqyo9VFFMybhDaCsgpump")
          }}>COPY</button>
        </div>
      </div>
      <div id='AddyBottom'>
        <div id='AddyLeft'>
          <h2>Join Forces</h2>
          <h1 style={{color:"orangered"}}>With Normal People</h1>
          <div id='AddyContainer'>
            <h1 style={{color:"white"}}>Fun Facts</h1>
            <h2>Normal People's favorite catchphrase: <br/> 2 + 2 = 4</h2>
            <h2>They are the ultimate people to <br/> live the life at its finest.</h2>
            <span>Normal People are the people that embody the logic, being reasonable and living the best out of their life.</span>
          </div>
        </div>
        <div id='AddyRight'>
          <img src={addyLeft} alt="" />
        </div>
      </div>
    </div>
  )
}

function Buy(){
  return(
    <div id='Buy'>
      <h1>How to Buy</h1>
      <div id='BuyContainer'>
        <div id='BuyCard'>
          <h2>Create Wallet</h2>
          <span>DOWNLOAD PHANTOM OR YOUR WALLET OF CHOICE FROM THE APP STORE OR GOOGLE PLAY FOR FREE. DESKTOP USERS: DOWNLOAD THE GOOGLE CHROME EXTENSION BY GOING TO PHANTOM.</span>
            <button onClick={e=>{window.open("https://phantom.app/")}}>GET PHANTOM</button>
        </div>
        <div id='BuyCard'>
          <h2>Get Some SOL</h2>
          <span>HAVE SOL IN YOUR WALLET TO SWITCH TO $NORMAL. IF YOU DON'T HAVE ANY SOL, YOU CAN BUY DIRECTLY ON PHANTOM, TRANSFER FROM ANOTHER WALLET, OR BUY ON ANOTHER EXCHANGE AND SEND IT TO YOUR WALLET.</span>
            <button onClick={e=>{window.open("https://phantom.app/")}}>BUY SOL</button>
        </div>
        <div id='BuyCard'>
          <h2>Go to Jupiter</h2>
          <span>CONNECT TO JUPITER. CONNECT YOUR WALLET IN CHROME, PASTE THE $CHILLGUY TOKEN ADDRESS, SELECT TRADE, AND CONFIRM. WHEN PHANTOM PROMPTS YOU FOR A WALLET SIGNATURE, SIGN.</span>
            <button onClick={e=>{window.open("https://jup.ag/swap/SOL-CyhnY44hgDSycXmpRuhMv9Ctqyo9VFFMybhDaCsgpump")}}>BUY NOW</button>
        </div>
        <div id='BuyCard'>
          <h2>Swap for NORMAL</h2>
          <span>SWITCH SOL FOR $NORMAL. WE HAVE ZERO TAXES SO YOU DON'T NEED TO WORRY ABOUT BUYING WITH A SPECIFIC SLIPPAGE. IF YOU DON'T HAVE ANY SOL, YOU CAN BUY DIRECTLY ON PHANTOM.</span>
          <button onClick={e=>{window.open("https://jup.ag/swap/SOL-CyhnY44hgDSycXmpRuhMv9Ctqyo9VFFMybhDaCsgpump")}}>SWAP NOW</button>          
        </div>
      </div>
    </div>
  )
}

function Generator(){
  const canvasRef = useRef(null)
  const [memeText, setMemeText] = useState("")
  const [memeText2, setMemeText2] = useState("")
  const [memeText3, setMemeText3] = useState("")
  const [memeText4, setMemeText4] = useState("")
  const [memeText5, setMemeText5] = useState("")
  const [memeTitle, setMemeTitle] = useState("")

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext("2d")
    const img = new Image()
    img.src = memeBase
    img.onload = () => {
      canvas.width = img.width
      canvas.height = img.height
      ctx.drawImage(img,0,0)
      ctx.font = "normal normal 900 70px Arial"
      ctx.fillStyle = "lightblue"
      ctx.textAlign = "center"
      ctx.fillText(memeTitle, canvas.width/1.3, canvas.height/10)
      ctx.font = "50px Arial"
      ctx.fillStyle = "black"
      ctx.textAlign = "center"
      ctx.fillText(memeText, canvas.width/1.3, canvas.height/4)
      ctx.font = "30px Arial"
      ctx.fillStyle = "black"
      ctx.textAlign = "center"
      ctx.fillText(memeText2, canvas.width/1.8, canvas.height/2.5)
      ctx.font = "30px Arial"
      ctx.fillStyle = "black"
      ctx.textAlign = "center"
      ctx.fillText(memeText3, canvas.width/1.7, canvas.height/3.2)
      ctx.font = "40px Arial"
      ctx.fillStyle = "black"
      ctx.textAlign = "center"
      ctx.fillText(memeText4, canvas.width/1.7, canvas.height/1.15)
      ctx.font = "25px Arial"
      ctx.fillStyle = "black"
      ctx.textAlign = "center"
      ctx.fillText(memeText5, canvas.width/1.9, canvas.height/1.4)

    }
    
  },[memeText, memeTitle, memeText2,memeText3,memeText4,memeText5])
 async function downloadMeme(){
  var canvas = document.getElementById("GeneratorCanvas")
  var url = canvas.toDataURL("image/png")
  var link = document.createElement("a")
  link.download = "normalPeopleMeme.png"
  link.href = url
  link.click()
 }

  return(
    <div id='Generator'>
      <h1>Meme Generator</h1>
      <canvas ref={canvasRef} id='GeneratorCanvas'></canvas>
      <div id='GeneratorSection'>
        <div id='GeneratorBundle'>
          <h2>Title</h2>
          <input placeholder='Title' type="text" value={memeTitle} onChange={e => setMemeTitle(e.target.value)}/>
        </div>
        <div id='GeneratorBundle'>
          <h2>Text 1</h2>
          <input placeholder='Text1' type="text" value={memeText} onChange={e => setMemeText(e.target.value)}/>
        </div>
        <div id='GeneratorBundle'>
          <h2>Text 2</h2>
          <input placeholder='Text2' type="text" value={memeText2} onChange={e => setMemeText2(e.target.value)}/>
        </div>
        <div id='GeneratorBundle'>
          <h2>Text 3</h2>
          <input placeholder='Text3' type="text" value={memeText3} onChange={e => setMemeText3(e.target.value)}/>
        </div>
        <div id='GeneratorBundle'>
          <h2>Text 4</h2>
          <input placeholder='Text4' type="text" value={memeText4} onChange={e => setMemeText4(e.target.value)}/>
        </div>
        <div id='GeneratorBundle'>
          <h2>Text 5</h2>
          <input placeholder='Text5' type="text" value={memeText5} onChange={e => setMemeText5(e.target.value)}/>
        </div>
      </div>
      
      <button onClick={e=>downloadMeme()}>Download Meme</button>
    </div>
  )
}

function About(){
  return(
    <div id='About'>
      <div id='AboutContainer'>
        <img src={about1} alt="" />
        <div id='AboutCard'>
          <h1>About</h1>
          <h2>Normal People are the people that embody the logic, being reasonable and living the best out of their life. They do not try to over-complicate things and make life harder.</h2>
        </div>
      </div>
      <div id='AboutContainer'>
        <div id='AboutCard'>
          <h1>Mission</h1>
          <h2>Our mission is to spread the lifestyle of being normal in a woke world. Through the Normal People movement, we are helping the people to discover the power of being normal, find their inner peace and learn that being woke is bullshit. No fuss, just vibes. Even Elon Musk is in the movement.</h2>
          <button onClick={e=> window.open("https://x.com/elonmusk/status/1867414209425735711")}>Elon Musk Tweet</button>
        </div>
        <img src={about2} alt="" />
      </div>
    </div>
  )
}

function Footer(){
  return(
    <div id='Footer'>
          <a href="https://x.com/NormalPeopleSOL">
          <img src={tw} alt="" />
          </a>
          <a href="https://t.me/NormalPeopleSOL">
          <img src={tg} alt="" />
          </a>
          <a href="#">
          <img src={dext} alt="" />
          </a>
          <a href="https://dexscreener.com/solana/CyhnY44hgDSycXmpRuhMv9Ctqyo9VFFMybhDaCsgpump"><img src={dex} alt="" /></a>
    </div>
  )
}
export default App;
